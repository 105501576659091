import React from "react"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({ data: { cover, moods } }) => (
  <Layout title={'Glacier'}>
    <SEO title="Glacier" />
    <div className="cover-images">
      {cover.edges.map(({node}, i) => (
        <Img
          fluid={node.childImageSharp.fluid}
          className="cover-img drop-shadow"
          alt={`Glacier – by PLEX NOIR – Cover image #${i + 1}`}
        />
      ))}
    </div>

    <div className="description">
    <p>Back in 2012, we visited some small islands in southern Iceland once used for puffin hunting. But the birds have left. We were told by the locals, that their main source of food, eel, avoids the warming waters in southern Iceland and has moved north in pursuit of colder regions.</p>

    <p>This struck us. The direct relation of global warming and changes in our flora and fauna were much more visible here than where we come from. Now, years later, we still feel deeply for the people and the nature of Iceland. Amidst the continuous stream of bad news regarding Iceland’s nature one piece especially filled us with sorrow: the symbolic funeral of the Okjökull glacier.</p>

    <p>This sorrow and our desire to advocate for change moved us to act. In reminiscence of the prophetic work of Paul Kos’ <q>The Sound of Ice Melting</q> (1970) we, the artist collective PLEX NOIR, want to emphasize the dire state of our planet’s climate in a reactive sound and light sculpture.</p>

    <p>Worldwide, instant access to information has made ignorance become a choice. Scientists have been flagging the dramatic fluctuations in earth’s climate for decades. School kids in every country march the streets to advocate for our planet. Yet, humankind still seems unable to commit to true, sustainable change.</p>

    <p>While we’re still stuck in never-ending discussions about strategies for the reduction of carbon emissions, their signs are already showing. The signals are getting more frequent and louder.</p>

    <p>In our work, we visualize exactly these signals that most of us miss in our busy buzzy lives. We use real data and leverage the neglected artifacts of technology to produce a highly aesthetically pleasing laser projection with an abstract sound landscape. The source for our generated signals will be climate change itself.</p>

    <p>To acquire real data from current climate science we will collaborate with Icelandic scientists. Our installation will then display actual (or even live) data on the accelerating transformation of our environment utilizing our code to generate fascinating and capturing sounds and laser interpretations. This captivating installation leaves few boundaries for imagination and scale, for example by using a real growler or iceberg as a canvas for the installation.</p>

    <p>When will the sound of ice cracking under our feet be loud enough for us to change?</p>

    <p><i>Glacier.</i> An installation by <a href="https://www.plexnoir.com">PLEX NOIR</a>.*</p>
    <p><small><em>* preferably displayed on a growler or an iceberg.</em></small></p>
    </div>

    <div className={'image-container'}>
    {moods.edges.map(({ node }, i) => (
      <Img
        fluid={node.childImageSharp.fluid}
        className={'mood-img drop-shadow'}
        alt={`Glacier – by PLEX NOIR – Mood image #${i + 1}`}
      />
    ))}
    </div>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    cover: allFile(filter:{
      extension: { regex:"/(jpeg|jpg|png)/" },
      relativeDirectory: { eq: "glacier" }
    }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1080, maxHeight: 768, toFormat: JPG) {
                ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    moods: allFile(filter:{
      extension: { regex:"/(jpeg|jpg|png)/" },
      relativeDirectory: { eq: "moods" }
    }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 200, maxHeight: 200, toFormat: JPG) {
                ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
